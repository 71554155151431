<template>
    <v-card class="mx-auto">
        <v-img :aspect-ratio="1754/1240" class="bg-black" :src="image_url" contain ></v-img>
        <v-card-title class="uyghur" >
            {{ title }}
        </v-card-title>
        <v-card-subtitle class="uyghur mb-5">
            {{ sub_title }}
        </v-card-subtitle>
    </v-card>
   
</template>
<script>
export default {
    name: 'CategoryCard',
    data() {
        return {
            
        }
    },
    props: [
        'image_url',
        'title',
        'sub_title'
    ]
}
</script>