<template>
  <v-app theme="light" class="bg-deep-orange-lighten-4">
    <v-locale-provider rtl>
      <NavBar />
      <v-main>
        <router-view />
      </v-main>
    </v-locale-provider>
  </v-app>
</template>

<script>
import NavBar from '@/components/Navbar.vue';

export default {
  name: 'App',

  components: {
    NavBar
  },

  data() {
    return {

    }
  }
}

</script>
<style scoped>
@font-face {
  font-family: alkatip;
  src: url('@/assets/font/ukijtuzk.ttf') format("opentype");
}

::v-deep(.uyghur) {
  font-family: alkatip !important;
}
</style>