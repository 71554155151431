<template>
  <v-sheet class="bg-transparent pa-12 my-auto" height="1200" rounded>
    <v-card flat class="bg-deep-orange-lighten-3 mx-auto px-6 py-8" max-width="344">
      <v-form v-model="form" class="uyghur text-white" @submit.prevent="onSubmit">
        <v-text-field hide-details="true" clearable color="deep-orange-lighten-5" variant="underlined" v-model="email" :readonly="loading" :rules="[required]" class="mb-2"
          prepend-inner-icon="mdi-email-outline" label="ئېمەيل"></v-text-field>

        <v-text-field hide-details="true" clearable color="deep-orange-lighten-5" variant="underlined" v-model="password" :readonly="loading"
          :append-inner-icon="hide_password ? 'mdi-eye-off' : 'mdi-eye'" :type="hide_password ? 'password' : 'text'"
          prepend-inner-icon="mdi-lock-outline" :rules="[required]" label="پارول" placeholder="Enter your password"
          @click:append-inner="hide_password = !hide_password">
        </v-text-field>

        <br>

        <v-btn :disabled="!form" :loading="loading" block color="deep-orange-lighten-1" size="large" type="submit"
          variant="elevated">
          كىرىش
        </v-btn>
      </v-form>
    </v-card>
  </v-sheet>
</template>
<script>
export default {
  data: () => ({
    hide_password: true,
    form: false,
    email: null,
    password: null,
    loading: false,
  }),

  methods: {
    async onSubmit() {
      if (!this.form) return

      this.loading = true
      //mine
      const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username: this.email, password: this.password })
      }
      const response = await fetch('https://anatil.uygar.space/api/login',requestOptions)
      this.loading = false
      if (response.status == 200) {
        const resp = await response.json()
        //console.log(resp.token)
        //console.log(resp.user)
        this.$store.commit('login', resp.token);
        this.$router.push({name: 'home'})
      }
      //console.log(resp.account.json())

      //setTimeout(() => (this.loading = false), 2000)
    },
    required(v) {
      return !!v || 'Field is required'
    },
  },
}
</script>