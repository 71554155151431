
<template>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
        <v-card elevation="5" class="bg-transparent ma-3" flat>
        <a :href="`https://www.ayhanedu.com/pdf/EdebiyatqaKirishMeshiq.pdf`">
          <v-img :src="require(`@/assets/materiyal/EdebiyatqaKirishMeshiq.jpg`)"></v-img>
        </a>
      </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
        <v-card elevation="5" class="bg-transparent ma-3" flat>
        <a :href="`https://www.ayhanedu.com/pdf/BirinchiYilliqMeshiq.pdf`">
          <v-img :src="require(`@/assets/materiyal/BirinchiYilliqMeshiq.jpg`)"></v-img>
        </a>
      </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>