<template>
    <v-sheet class="bg-transparent pa-12 uyghur" rounded>
      <v-card class="mx-auto px-6 py-8" max-width="344">
        <div class="text-center text-h5 text-uppercase uyghur">تاپشۇرۇق يوللاش</div>
        <br>
        <v-form class="uyghur" v-model="form" @submit.prevent="onSubmit">
          <v-combobox
              v-model="student"
              :items="this.students"
              variant="outlined"
            ></v-combobox>
            <v-file-input variant="outlined" small-chips show-size clearable multiple label="Logo" v-model="homework.attachments" @change="inputChanged">
            </v-file-input>
            <v-btn width="80" :disabled="!form" rounded :loading="loading" block class="text-white bg-deep-orange-lighten-1" type="submit"
              variant="elevated">
              يوللا
            </v-btn>
        </v-form>
      </v-card>
    </v-sheet>
  </template>
<script>
import axios from 'axios'
  //axios.defaults.withCredentials = true

  export default {
    components: {
    },
    data() { 
      return {
        hide_password: true,
        form: false,
        student: null,
        students: [],
        homework: {
          id: 122,
          attachments: []
        },
        loading: false,
      }
    },

    created() {
        fetch('https://anatil.uygar.space/api/oqughuchi/', {
            method: 'GET',
        })
        .then(resp => resp.json())
        .then(resp => this.students = resp.map(this.getFullName))
        .catch(error => console.log(error))
    },
  
    methods: {
      async onSubmit() {
        if (!this.form) return
        this.loading = true
        let formData = new FormData()
        formData.append('id', this.homework.id)

        for (let i = 0; i < this.homework.attachments.length; i++) {
          formData.append('attachments', this.homework.attachments[i])
        }
  
        this.loading = true
        await axios
          //.post('https://anatil.uygar.space/api/upload_homework', formData, {
          .post('http://localhost:8000/api/upload_homework', formData, {
            headers: { "Content-Type": "multipart/form-data" }
          })
          .then((response) => console.log(response))
        this.loading = false
      },
      inputChanged () {
        console.log(this.homework.attachments[0])
      },
      required(v) {
        return !!v || 'Field is required'
      },
      getFullName(item) {
        return [item.isim,item.famile].join(" ");
      }
    },
  }
  </script>
  <style scoped>
  .v-file-input {
  color: rgb(65, 61, 61) !important;
  .menu-dropdown .v-list {
    color:red;
  }
}
  </style>