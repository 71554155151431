<template>
    <v-sheet max-width="1200" class="mx-auto">
        <v-layout>
            <v-main>
                <v-locale-provider rtl>
                    <v-container class="bg-deep-orange-lighten-5 mx-auto">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <CategoryCard height="100%" :image_url="require('@/assets/shuar.jpg')"
                                    :title="'ئۇيغۇر ئانا تىل تور مەكتىپى'"
                                    :sub_title="'ئۇيغۇرلۇقىمىزدا بالىلارنىڭمۇ ھەققى بار!'" />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <CategoryCard height="100%" :image_url="require('@/assets/yazghuchilar.png')"
                                    :title="'ئۇيغۇر بالا يازغۇچىلار جەمئىيىتى'" :sub_title="'بىز يازىدىغان بىر ئەتە بار'" />
                            </v-col>
                        </v-row>
                        <p class="uyghur mt-5 text-h5 mx-5">
                            تۆتقۇلاق ژۇرنىلى
                        </p>
                        <v-slide-group>
                            <v-slide-group-item v-for="i in 12" :key="i" v-slot:default="{ active, toggle }">
                                <v-card elevation="5" class="bg-transparent ma-3" flat width="200">
                                    <v-img contain :src="require(`@/assets/jurnal/${i}.jpg`)"
                                        :color="active ? 'primary' : undefined" @click="toggle"></v-img>
                                </v-card>
                            </v-slide-group-item>
                        </v-slide-group>
                        <p class="uyghur mt-5 text-h5 mx-5">
                            ئۇچقۇن بالىلار گېزىتى
                        </p>
                        <v-slide-group>
                            <v-slide-group-item v-for="i in 19" :key="i" v-slot:default="{ active, toggle }">
                                <v-card elevation="5" class="bg-transparent ma-3" flat width="250">
                                    <a :href="`https://www.ayhanedu.com/pdf/uchqun${i}.pdf`">
                                        <v-img contain :src="require(`@/assets/gezit/${i}.jpg`)"
                                        :color="active ? 'primary' : undefined" @click="toggle"></v-img>
                                    </a>
                                </v-card>
                            </v-slide-group-item>
                        </v-slide-group>
                    
                    </v-container>
                    
                </v-locale-provider>
            </v-main>
        </v-layout>
    </v-sheet>
    <v-dialog v-model="dialog" max-width="100%">
        <v-card>
            <v-card-title class="headline">PDF Viewer</v-card-title>
            <v-card-text>
            <embed :src="pdfUrl" type="application/pdf" width="100%" height="600px" />
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  
<script>
import { defineComponent } from 'vue';
import CategoryCard from '@/components/CategoryCard.vue';
export default defineComponent({
    name: 'SchoolView',
    components: {
        CategoryCard
    },
    data() {
        return {
        dialog: false,
        pdfUrl: 'https://ayhanedu.com/pdf/uchqun01.pdf', // Replace with the actual URL of your PDF
        };
    },
    methods: {
        showPdf() {
            this.dialog = true;
        },
    },
});

</script>
<style scoped>
.darken:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);
}
</style>
  