<template>
  <v-sheet max-width="1200" height="100%" class="mx-auto bg-deep-orange-lighten-5">
  <v-locale-provider rtl>
    <v-layout justify-center align-center>
    <v-avatar class="outlined mx-auto my-5" size="180">
        <v-img class="bg-white" contain src="@/assets/ayhan512.png"></v-img>
    </v-avatar>
  </v-layout>
    <h2 class="text-center mb-5 text-h3 uyghur">ئايخان مەدەنىيەت ۋە مائارىپ فوندى ھەققىدە</h2>
    <p class="mx-5 my-5 text-h6 uyghur">
1. يېتىم بالىلار ئۈچۈن ئۆز ئۆيىنى مەكتەپ قىلىپ ئاچقان ئايخان ئانىنىڭ نامىنى ۋە ئەمگەكلىرىنى ياشىتىش ئۈچۈن 2015 - يىلى 12 - ئايدا تور مەكتەپ سۈپىتىدە قۇرۇلغان، چەتئەل تىلى كۇرسلىرىدىن كىرگەن كىرىمنىڭ بىر قىسمى ئارقىلىق «ئايخان فوندى» تەسىس قىلىنغان.<br>
2. 2016 - يىلى 12 - ئايدا ئالىي مەكتەپتە ئوقۇۋاتقان ئالتە قىز ئوقۇغۇچىغا «ئايخان فوندى قىزلار ئوقۇش مۇكاپاتى» تارقىتىلغان.<br>
3. 2017 - يىلى 11 - ئايدا ئىستانبۇلدا تۇنجى قارارلىق «ساۋات چىقىرىش سىنىپى» ئېچىلىپ، 20 ئوقۇغۇچىنى تەربىيەلەنگەن.4.  2018 -  يىلى 11- ئايدا «ئېلىپبە سىنىپى» ۋە «ئەدەبىيات سىنىپى» ئېچىلىپ، جەمئىي 47 ئوقۇغۇچى تەربىيەلەنگەن.  <br>
5. 2019 - يىلى 4- ئايدا ئەۋلاد نەشرىياتى بىلەن ھەمكارلىشىپ «تۆتقۇلاق بالىلار ژۇرنىلى» نەشر قىلىنىشقا باشلاپ، ھازىرغىچە 21 سان ژۇرنال تارقىتىلغان. <br>
6. 2019 - يىلى 7- ئايدا «ئايخان مائارىپى» تۈركىيە سىرتىدىكى دۆلەتلەردىن كەلگەن ئوقۇغۇچىلار بىلەن «ئايخان مائارىپى»دا تەربىيەلىنىۋاتقان بالىلار ئۈچۈن «مەدەنىيەت ئالماشتۇرۇش سىنىپلىرى» تەسىس قىلىنىپ، بالىلارنىڭ سەۋىيەسىگە ئاساسەن ئۇيغۇر ئېلىپبەسى، ئۇيغۇر ئېغىز ئەدەبىياتى دەرسلىرى، ئۇيغۇر خەتتاتلىقى، ئۇيغۇر چالغۇلىرى، ئۇيغۇر بالىلار ئويۇنلىرى، كەشتە، دوپپا ياساش قاتارلىق مەدەنىيەت دەرسلىرى ئورۇنلاشتۇرۇلغان. بالىلارغا ئىستانبۇلدىكى مۇزېي ۋە تارىخىي ئورۇنلار ئېكىسكۇرسىيە قىلدۇرۇلغان.<br>
7. 2019 - يىلى 7 - ئاينىڭ 28 - كۈنى «مەن — ناتىق» ئۇيغۇر غۇنچىلىرى نۇتۇق سۆزلەش مۇسابىقىسى ئۆتكۈزۈلگەن، كېيىنكى يىلىدىن باشلاپ، نۇتۇق مۇسابىقىسى توردا ئۆتكۈزۈلۈشكە باشلىغان ۋە ھازىرغىچە جەمئىي بەش قېتىملىق مۇسابىقە ئورۇنلاشتۇرۇلغان.<br>
8. 2020 - يىلى «ئۇيغۇر ئانا تىل تور مەكتىپى» قۇرۇلۇپ، تور مەكتەپنىڭ چىقىملىرى فوندى تەرىپىدىن تەمىنلەنگەن. ھازىر بۇ مەكتەپتە ئېلىپبە سىنىپىدىن تولۇقسىز 1- يىللىق ئەدەبىيات سىنىپىغىچە بولغان 21 سىنىپتا ھەرقايسى دۆلەتلەردىن جەمئىي 338 ئوقۇغۇچى ھەقسىز تەربىيەلەنمەكتە.<br>
9. 2024 - يىلى «ئۇيغۇر ئانا تىل تور مەكتىپى» تەرىپىدىن تۈزۈلگەن «ئەدەبىياتقا كىرىش» ۋە 1- يىللىقلار ئۈچۈن «تىل - ئەدەبىيات» كىتابى نەشر قىلىنغان.<br>
</p>
  </v-locale-provider>
  </v-sheet>
</template>
<style scoped>
.uyghur {
    font-family: alkatip !important;
    direction: rtl;
}
</style>
