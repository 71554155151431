import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView'
import UploadHomework from '../views/UploadHomework'
import School from '@/views/School.vue'
import Gezit from '@/views/GezitView.vue'
import About from '@/views/AboutView.vue'
import Accessory from '@/views/AccessoryView.vue'
import Appendix from '@/views/AppendixView.vue'
//import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'home',
    component: School
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/logout',
    name: 'logout',
    component: null
  },
  {
    path: '/biz',
    name: 'biz',
    component: About
  },
  {
    path: '/meshiq',
    name: 'meshiq',
    component: Accessory
  },
  {
    path: '/qollanma',
    name: 'qollanma',
    component: Appendix
  },
  {
    path: '/uchqun',
    name: 'uchqun',
    component: Gezit
  },
  {
    path: '/upload_homework',
    name: 'upload_homework',
    component: UploadHomework
  },
  {
    path: '/homeworks',
    name: 'homeworks',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Homeworks.vue')
  },
  {
    path: '/school',
    name: 'school',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/School.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.name === 'logout') {
//     store.commit('logout')
//     router.push({name: 'home'})
//   }
//   next();
// });


export default router
