import { createStore } from 'vuex'

export default createStore({
  state: {
    accessToken: null,
  },
  getters: {
  },
  mutations: {
    login(state, token) {
      state.accessToken = token
      localStorage.setItem('accessToken', token)
    },
    logout(state) {
      state.accessToken = ""
      localStorage.removeItem('accessToken')
      console.log('access token removed')
    },
    initializeStore(state) {
      if (localStorage.getItem('accessToken')) {
        state.accessToken = localStorage.getItem('accessToken')
      }
    },
  },
  actions: {
  },
  modules: {
  }
})
