
<template>
  <v-container>
    <v-row>
      <v-col
        v-for="i in 19" :key="i"
        cols="12"
        md="6"
      >
      <v-card elevation="5" class="bg-transparent ma-3" flat>
      <a :href="`https://www.ayhanedu.com/pdf/uchqun${i}.pdf`">
        <v-img :src="require(`@/assets/gezit/${i}.jpg`)"></v-img>
      </a>
    </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>